<template>

  <div class="container">
    <Masonry title="解决方案" category_path="solution" :category_id="categoryID" v-if="update"/>
  </div>

</template>
<script>
import Masonry from "../components/masonry";

export default {
  name: "List",
  components: {
    Masonry
  },
  data() {
    return {
      categoryID: "2",
      update: true
    };
  },
  created: function () {
    // console.log("solution:", this.$route);
    if (this.$route.params != {} && this.$route.params.category != 'all') {
      this.categoryID = this.$route.params.category;
    } else {
      this.categoryID = "2"
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        //console.log(val, oldVal);
        if (val.params != {} && val.params.category != 'all') {
          this.categoryID = "" + val.params.category;
        } else {
          this.categoryID = "2"
        }
        //this.$forceUpdate();
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      // 移除组件
      this.update = false
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.update = true
      })
    }
  }
};
</script>
